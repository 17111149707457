import { Component, Input } from '@angular/core';
import { Clo, CourseSection } from '../../interfaces/aol-course.interface';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from '../../../../shared/components/charts/progress-bar/progress-bar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-sections',
  standalone: true,
  imports: [TranslateModule, ProgressBarComponent],
  templateUrl: './course-sections.component.html',
  styleUrl: './course-sections.component.scss',
})
export class CourseSectionsComponent {
  @Input({ required: true }) courseSections!: CourseSection[];
  @Input({ required: true }) cloBenchmark!: number;
  @Input({ required: true }) clo!: Clo;

  constructor(private readonly router: Router) {}

  navigateToCourseSectionAol(courseSection: CourseSection) {
    this.router.navigate(['course-sections', courseSection._id, 'aol']);
  }
}
